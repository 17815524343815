.navbar {
  background-color: rgb(39, 58, 94);
  vertical-align: middle;
  
}
.player1{
  width: 50px;
  height: 5vh;  
}
.caa{
  margin-right: 2%;
  margin-left: 2%;
  top: -2px;
  position: relative;
  cursor: pointer;

  
}
.caa2{
  width: 20%;
  height: 30%;
  margin-right: 2%;
  margin-left: 2%;
  top: -2px;
  position: relative;
  
  
}
.navbar .header {
    color: rgb(255, 255, 255);
  font-size: 37px;
  border-radius: 37px;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
 
}

.menu-bars {
  font-size: 25px;
  background: none;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 20px;
}
.x-button {
  font-size: 25px;
  background: none;
  cursor: pointer;
  margin-right: 20px;
  margin-left: 0px;
}

.nav-menu {
  z-index: 12;
  background-color: rgb(47, 71, 116);
  width: 220px;
  height: 100vh;
  display: flex;
  justify-content: left;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
 
  
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
  height: 60px;
}


.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: left;

  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
  color: #f5f5f5;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color:rgb(47, 71, 116);
  width: 0%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
 }

span {
  margin-left: 16px;
}

