@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  
}
.Cancelled {
  background: lightgray;
   color: black;
 } 

 .NoShow {
  background: rgb(123, 123, 123);
   color: black;
 } 

 .OnDeck {
  background: rgb(207, 142, 142);
   color: black;
 } 

 .Completed {
  background: rgb(195, 235, 195);
   color: black;
 } 

 .Playing {
  background: rgb(150, 183, 235);
   color: black;
 } 
.matches,
.players,
.courts {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.match{
  display: flex;
  width: 90px;
  height: 5.5vh;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  
}

.player-right {
  margin-right: 1%;
}
.player-left {
  margin-left: 1%;
}



.fixTableHead {
  overflow-y: auto;
  height: 600;
}
.fixTableHead table {
  text-align: left;
  position: relative;
}


.fixTableHead thead th {
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}



.name{
  float: left; 

}

.nameR{
  float: right;
  color:aliceblue;
  padding-right:28px;
}
.school{
  float: right;
  padding-right: 20px;
 
}
.print{
  float: right;
  padding-right: 20px;
}
.userdetail{
  background-color: greenyellow;
}

.container { 
  position:relative;
  padding:0;
  margin:0;
}
.input { 
  height:30px;
  margin:0;
  padding-left:28px;
}
.image {
  position:absolute;
  bottom:0px;
  left:14px;
  width:30px;
  height:30px;
}
.print{
  cursor: pointer
}
.inputsearch {
  margin-left: 10px;
    background-image: url(/static/media/search.6161574c.png);
    background-size: 20px 20px;
    background-position: 98%;
    background-repeat: no-repeat;
    text-indent: 7px;
    width: 200px;
    height: 35;
    border-radius: 37px;
    border-color: grey;
  
}
.icon-button-img{
  fill: var(--text-color);
  width: 30px;
  height: 30px;
}
.page-name{
  text-align: center!important;
  
}
.login{
  background-color:  #eeeded;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  border: 1px solid #E1E7EC;
  
    position: fixed;
    width: 30%;
    text-align: center;
   
    right: -0%;
    height: 100%;
   
}
.teamstanding{
  background-color: #eeeded;
  border-radius: 20px;
  border: 1px solid #E1E7EC;
  
    position: fixed;
    width: 90%;
    text-align: center;
    top: 20%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}
.helpcontainer{
  max-width: 1000px;
  width: 100%;
  margin: auto;
}
.helpcards{
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.helpcard{
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.33% -20px);
  max-width: calc(33.33% -20px);
  width:100%;
  background:#eaf4ff;
  border-radius:20px;
  padding: 20px;
  padding-bottom: 5px;
  margin-bottom: 40px;
  transition: 0.3s;
  border: 2px solid transparent
}
.helpcard:hover{
  border-color: #1d39c4;
}
.btn{
  border: none;
  outline: none;
}
.eye{
  position: absolute;
  right:30px;
  -webkit-transform: translate(0,-20%);
          transform: translate(0,-20%);
  top: 50%;
  z-index: 20px;
  cursor: pointer;
}
.logotext {
  color: rgb(39, 58, 94);
font-size: 37px;
border-radius: 37px;
font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

}
.footer {
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height: 60px;  
}

.rowC{display:flex; flex-direction:row; align-items: center ;}
.navbar {
  background-color: rgb(39, 58, 94);
  vertical-align: middle;
  
}
.player1{
  width: 50px;
  height: 5vh;  
}
.caa{
  margin-right: 2%;
  margin-left: 2%;
  top: -2px;
  position: relative;
  cursor: pointer;

  
}
.caa2{
  width: 20%;
  height: 30%;
  margin-right: 2%;
  margin-left: 2%;
  top: -2px;
  position: relative;
  
  
}
.navbar .header {
    color: rgb(255, 255, 255);
  font-size: 37px;
  border-radius: 37px;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
 
}

.menu-bars {
  font-size: 25px;
  background: none;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 20px;
}
.x-button {
  font-size: 25px;
  background: none;
  cursor: pointer;
  margin-right: 20px;
  margin-left: 0px;
}

.nav-menu {
  z-index: 12;
  background-color: rgb(47, 71, 116);
  width: 220px;
  height: 100vh;
  display: flex;
  justify-content: left;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
 
  
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
  height: 60px;
}


.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: left;

  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
  color: #f5f5f5;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color:rgb(47, 71, 116);
  width: 0%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
 }

span {
  margin-left: 16px;
}


.dropDownProfile{
  position: absolute;
  top: 4.6rem;
  z-index: 20;
  right:0.75%;
  width: 120px;
  padding: 7px;
  border-radius: 8px;
  background-color: #E1E7EC;
  border: 1px solid gray;
}

.dropDownProfile::before{
  content: '';
  position: absolute;
  top: -0.7rem;
  right: 1.1rem;
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #E1E7EC;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
}
.icon-button{
  background-color: #62645e;
  border-radius:50%;
  padding: 3px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}
.icon-button:hover{
  -webkit-filter:brightness(1.7);
          filter:brightness(1.7);
}
.dropdown-button{
  background-color: rgb(39, 58, 94);
  width: 90px;
  
  border-radius:5px;
  padding: 5px;
  margin: 7px;
  display: flex;
  align-items: center;
  
  transition: -webkit-filter 300ms;
  
  transition: filter 300ms;
  
  transition: filter 300ms, -webkit-filter 300ms;
}
.dropdown-button:hover{
  -webkit-filter:brightness(2);
          filter:brightness(2);
  cursor: pointer;
}
.text{
  
    color: rgb(255, 255, 255);
    font-size: 17px;
  
  
}
